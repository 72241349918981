import * as yup from "yup";
const RecallCategory = yup.object().shape({
  name: yup.string().required("Category name For Recall is required"),
  recallId: yup
    .number()
    .nullable()
    .transform((value, original) => (original === "" ? null : Number(value))),
  isLive: yup.boolean().required("Status is required"),
});
const AddRecallCategory = yup.object().shape({
  name: yup.string().required("Category name For Recall is required"),
  recallId: yup.number().required(),
  isLive: yup.boolean().required("Status is required"),
});

export { RecallCategory, AddRecallCategory };
