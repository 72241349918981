import React, { useEffect, useState } from "react";
import { recallQuestions } from "../../../validations/questions-recall";
import { useForm } from "react-hook-form";
import { API } from "../../../api";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../hooks/queryParam";
import { errorToast, successToast } from "../../../hooks/useToast";
import Header from "../../../components/dashboard/Header";
import InputField from "../../../components/general/InputField";
import Editor from "../../../components/general/Editor";
import ButtonComponent from "../../../components/general/ButtonComponent";
import EditRadioOptions from "../../../components/general/EditRadioOptions";
import EditCheckboxOptions from "../../../components/general/EditCheckboxOptions";
import Loader from "../../../components/general/Loader";
import { yupResolver } from "@hookform/resolvers/yup";

const EditQuestions = () =>
{
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState(true);
  const [isInvalid, setIsInvalid] = useState(false);
  const navigate = useNavigate();
  let query = useQuery();
  let id = Number(query.get("id"));

  const difficultyMode = [
    {
      id: "easy",
      key: "easy",
      name: "easy",
    },
    {
      id: "moderate",
      key: "moderate",
      name: "moderate",
    },
    {
      id: "difficult",
      key: "difficult",
      name: "difficult",
    },
  ];
  const anstype = [
    {
      id: "one",
      key: "one",
      name: "one",
    },
    {
      id: "multiple",
      key: "multiple",
      name: "multiple",
    },
  ];
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,

    formState: { errors },
  } = useForm({ resolver: yupResolver(recallQuestions) });

  const [allrecalls, setAllRecalls] = useState([]);
  const [options, setOptions] = useState([]);

  const getRecallData = async () =>
  {
    try
    {
      const response = await API.getAllRecalls();
      setAllRecalls(response?.data?.data);
    } catch (error)
    {
      errorToast(error, "Cannot fetch recalls");
    }
  };

  const [allcategory, setAllCategory] = useState([]);

  const getCategoryData = async () =>
  {
    try
    {
      const response = await API.getAllCategoriesRecall();
      setAllCategory(response?.data?.data);
      console.log("allcategory = ", response?.data?.data); // TODO: remove
    } catch (error)
    {
      errorToast(error, "Cannot fetch recalls");
    }
  };

  useEffect(() =>
  {
    getRecallData();
    getCategoryData();
  }, []);

  //get single start
  const [data, setData] = useState(null);
  const getData = async () =>
  {
    try
    {
      const response = await API.getSingleQuestionRecall(id);
      setData(response?.data?.recallId);
      setData(response?.data?.categoryRecallId);
      setData(response?.data?.defaultValue);
      setData(response?.data?.data);
      setOptions(response?.data?.data?.answer?.option);
      setValue("name", response?.data?.data?.name);
      setValue("type", response?.data?.data?.answer?.type);
      setValue("answerDetails", response?.data?.data?.answer?.answerDetails);
      setValue(
        "referenceDetails",
        response?.data?.data?.answer?.referenceDetails
      );
      if (response?.data?.data?.questionDate)
      {
        setValue("questionDate", response.data.data.questionDate.substring(0, 7));
      }
      setPageData(true);
    } catch (error)
    {
      errorToast(error, "Can not fetch data");
    }
  };

  useEffect(() =>
  {
    getData();
  }, [id]);

  //get single end

  const onSubmit = async (formData) =>
  {
    setLoading(true);
    // Format date as YYYY-MM-01
    let questionDate = null;
    if (formData?.questionDate)
    {
      // Add "-01" to the month value to create YYYY-MM-01 format
      questionDate = `${formData.questionDate}-01`;
    }
    try
    {
      const payload = {
        name: formData.name,
        isTrial: Boolean(formData.isTrial),
        isLive: Boolean(formData.isLive),
        difficultyMode: formData.difficultyMode,
        questionDate: questionDate,
        recallId: Number(formData.recallId) || Number(data?.recallId),
        categoryRecallId: Number(formData.categoryRecallId) || Number(data?.categoryRecallId),
        answers: {
          type: formData.type,
          answerDetails: formData.answerDetails,
          referenceDetails: formData.referenceDetails,
          options: options
            ? options.map((option) => ({
              id: option?.id,
              name: option.name,
              isCorrect: option.isCorrect,
            }))
            : data?.answer?.option?.map((item) => ({
              id: item?.id,
              name: item?.name,
              isCorrect: item?.isCorrect,
            })),
        },
      };

      const response = await API.updateQuestionRecall(id, payload);
      successToast(response?.data?.message);
      setLoading(false);
      navigate(-1);
    } catch (error)
    {
      setLoading(false);
      errorToast(error, "Cannot update questions");
    }
  };

  const handleCancle = () =>
  {
    navigate(-1);
  };

  const correctOptions = options.filter((option) => option.isCorrect);

  return (
    <div className="page-area mt-10">
      <Header
        pagetitle={"Questions"}
        previous={"Dashboard"}
        currentpage={"Edit Questions"}
      />
      {data && correctOptions && options ? (
        <form
          className="grid grid-col-1 gap-6"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="page-comp bg-white mt-10 rounded-xl px-8 py-8">
            <div className="grid grid-col-1 sm:grid-cols-3 gap-4  ">
              <InputField
                label="Recall Name"
                type="select"
                options={allrecalls}
                defaultValue={data?.recallId}
                placeholder="Recall Name"
                errors={errors}
                name="recallId"
                register={register}
              />
              <InputField
                label="Category Name"
                type="select"
                options={allcategory}
                defaultValue={data?.categoryRecallId}
                placeholder="Category Name"
                errors={errors}
                name="categoryRecallId"
                register={register}
              />
              <InputField
                label="Question Date"
                type="month"
                placeholder="Select Month and Year"
                defaultValue={data?.questionDate ? data.questionDate.substring(0, 7) : ""}
                errors={errors}
                name="questionDate"
                register={register}
              />
            </div>
            <div className="grid grid-col-1 sm:grid-cols-3 gap-4 mt-4">
              <InputField
                label="Difficulty Level"
                placeholder="Difficulty Level"
                type="select"
                options={difficultyMode}
                defaultValue={data?.difficultyMode}
                errors={errors}
                name="difficultyMode"
                register={register}
              />
              <InputField
                label="Status"
                type="select"
                options={[true, false]}
                placeholder="Status"
                defaultValue={data?.isLive}
                errors={errors}
                name="isLive"
                register={register}
              />
              <InputField
                label="isTrial"
                type="select"
                options={[true, false]}
                placeholder="isTrial"
                defaultValue={data?.isTrial}
                errors={errors}
                name="isTrial"
                register={register}
              />
            </div>
            <div className="grid grid-col-1  gap-4  mt-4">
              <Editor
                label="Statement"
                errors={errors}
                name="name"
                defaultValue={data?.name}
                register={register}
                setValue={setValue}
              />
            </div>
            <div className="grid grid-col-1 sm:grid-cols-1 gap-4 mt-4">
              <InputField
                label="Answer Type:"
                placeholder="Answer Type:"
                type="select"
                options={anstype}
                defaultValue={data?.answer.type}
                errors={errors}
                name="type"
                register={register}
              />
              {watch("type") === "one" && (
                <>
                  {options && (
                    <EditRadioOptions
                      isRequired={false}
                      defaultValue={correctOptions}
                      errors={errors}
                      name="options"
                      register={register}
                      control={control}
                      setOptions={setOptions}
                      options={options}
                    />
                  )}
                </>
              )}

              {watch("type") === "multiple" && (
                <EditCheckboxOptions
                  isRequired={false}
                  defaultValue={data?.answer?.type}
                  errors={errors}
                  name="options"
                  register={register}
                  control={control}
                  setOptions={setOptions}
                  options={options}
                />
              )}
            </div>
            <div className="grid grid-col-1  gap-4  mt-4">
              <Editor
                label="Answer Details:"
                errors={errors}
                name="answerDetails"
                defaultValue={data?.answer?.answerDetails}
                register={register}
                setValue={setValue}
              />
            </div>
            <div className="grid grid-col-1  gap-4  mt-4">
              <Editor
                label="Reference Details:"
                errors={errors}
                name="referenceDetails"
                defaultValue={data?.answer?.referenceDetails}
                register={register}
                setValue={setValue}
              />
            </div>
            <div className="w-full md:w-1/4 mt-4">
              <div className="flex gap-3">
                <ButtonComponent
                  type="submit"
                  text="Save"
                  loading={loading}
                  isActive={true}
                />
                <ButtonComponent
                  text="Cancel"
                  isActive={true}
                  btnclass={"bg-red-500"}
                  onClick={() => handleCancle()}
                />
              </div>
            </div>
          </div>
        </form>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default EditQuestions;
