import React, { useEffect, useState } from "react";
import Filterbar from "../../components/general/Filterbar";
import Overview from "../../components/general/Overview";
import Tableform from "../../components/general/Tableform";
import { tablecolumns, tablecolumns1 } from "../../data/tablecolumns";
import Header from "../../components/dashboard/Header";
import { API } from "../../api";
import { errorToast } from "../../hooks/useToast";
import { getLocalTimeZone, parseDate } from "@internationalized/date";
import { formatDate } from "../../utils/date";
import { useDateFormatter } from "@react-aria/i18n";
import Loader from "../../components/general/Loader";
import GraphsComponent from "../../components/dashboard/Graphs";

const Indexdashboard = () => {
  const [itemPerPage, setitemPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [allusers, setAllUsers] = useState(null);
  const [allusers1, setAllUsers1] = useState(null);
  let formatter = useDateFormatter();
  const [choice, setChoice] = useState(1);
  const [dashboardData, setDashboardData] = useState(null);
  const [recalltotalRevenue, setRecallTotalRevenue] = useState(null);

  const [value, setValue] = useState({
    start: parseDate(
      formatDate(new Date(new Date().setMonth(new Date().getMonth() - 3)))
    ),
    end: parseDate(formatDate(new Date())),
  });

  const getQbankSubscription = async () => {
    try {
      const response = await API.SubscriptionUser();
      setAllUsers(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorToast(error, "Can not fetch data");
    }
  };

  // const getRecallSubscription = async () => {
  //   try {
  //     const response = await API.SubscriptionRecall();
  //     setAllUsers1(response?.data?.data);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     errorToast(error, "Can not fetch data");
  //   }
  // };

  const getStatistics = async () => {
    try {
      const response = await API.getStatistics(
        formatter.format(value.start.toDate(getLocalTimeZone())),
        formatter.format(value.end.toDate(getLocalTimeZone())),
        choice
      );
      setDashboardData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      errorToast(error, "Can not fetch data");
      setLoading(false);
    }
  };
  const getRecallSubscription = async () => {
    try {
      const response = await API.SubscriptionRecall();
      const users = response?.data?.data || [];

      // Calculate total revenue
      const totalRevenue = users.reduce((sum, user) => {
        const price = parseFloat(user.price.replace("$", "")) || 0;
        return sum + price;
      }, 0);

      setAllUsers1(users);
      setRecallTotalRevenue(totalRevenue); // Store the total revenue in state
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorToast(error, "Can not fetch data");
    }
  };

  useEffect(() => {
    getStatistics();
  }, [value, choice]);

  useEffect(() => {
    getQbankSubscription();
    getRecallSubscription();
  }, []);

  return (
    <div className="page-area mt-10">
      <Header pagetitle={"Dashboard"} />

      {loading ? (
        <Loader />
      ) : (
        <div className="page-comp bg-white mt-10 rounded-xl px-8 py-8">
          <Filterbar
            setChoice={setChoice}
            setValue={setValue}
            value={value}
            choice={choice}
          />
          <Overview
            dashboardData={dashboardData}
            recalltotalRevenue={recalltotalRevenue}
          />
          <GraphsComponent />
          {allusers && allusers.length > 0 && (
            <Tableform
              tablecolumns={tablecolumns}
              filterdata={allusers}
              itemPerPage={itemPerPage}
              tableheading="Recent Subscription Members"
            />
          )}

          {allusers && allusers.length > 0 && (
            <Tableform
              tablecolumns={tablecolumns1}
              filterdata={allusers1}
              itemPerPage={itemPerPage}
              tableheading="Recall Members"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Indexdashboard;
