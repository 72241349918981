import * as yup from "yup";
const subscriptionRecallschema = yup.object().shape({
  name: yup.string().required("Recall name is required"),
  recallId: yup
    .number()
    .nullable()
    .transform((value, original) => (original === "" ? null : Number(value))),
  duration: yup
    .number()
    .required("Total time is required")
    .positive("Total time must be positive"),

  price: yup
    .number()
    .required("price required")
    .positive("price must be positive"),
  reSubscriptionPrice: yup
    .number()
    .required("reSubscriptionPrice required")
    .positive("reSubscriptionPrice must be positive"),
  durationMode: yup.string().required("duration Mode is required"),
  isLive: yup.boolean().required("Status is required"),
  isTrial: yup.boolean().required(" Trial is required"),
});

const addSubscriptionRecall = yup.object().shape({
  name: yup.string().required("Recall name is required"),
  recallId: yup.number().required(),
  duration: yup
    .number()
    .required("Total time is required")
    .positive("Total time must be positive"),

  price: yup
    .number()
    .required("price required")
    .positive("price must be positive"),
  reSubscriptionPrice: yup
    .number()
    .required("reSubscriptionPrice required")
    .positive("reSubscriptionPrice must be positive"),
  durationMode: yup.string().required("duration Mode is required"),
  isLive: yup.boolean().required("Status is required"),
  isTrial: yup.boolean().required(" Trial is required"),
});

export { subscriptionRecallschema, addSubscriptionRecall };
