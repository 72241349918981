const feedbackrecallcolumns = [
  {
    key: "num",
    label: "SN.",
  },
  {
    key: "detail",
    label: "Feed Back",
  },
  {
    key: "questionRecallId",
    label: "Question Recall Id",
  },
  {
    key: "userName",
    label: "User Name",
  },
  {
    key: "ip",
    label: "Ip",
  },
  {
    key: "date",
    label: "Date",
  },
  {
    key: "star",
    label: "Star",
  },
  {
    key: "actions",
    label: "Actions",
  },
];

export { feedbackrecallcolumns };
