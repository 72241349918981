import React, { useEffect, useState } from "react";
import Header from "../../../components/dashboard/Header";
import InputField from "../../../components/general/InputField";
import ButtonComponent from "../../../components/general/ButtonComponent";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { API } from "../../../api";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../../../hooks/useToast";
import Editor from "../../../components/general/Editor";
import RadioOptions from "../../../components/general/RadioOptions";
import CheckboxOptions from "../../../components/general/CheckboxOptions";
import { recallQuestions } from "../../../validations/questions-recall";

const AddQuestions = () =>
{
  const [isInvalid, setIsInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const difficultyMode = [
    {
      id: "easy",
      key: "easy",
      name: "easy",
    },
    {
      id: "moderate",
      key: "moderate",
      name: "moderate",
    },
    {
      id: "difficult",
      key: "difficult",
      name: "difficult",
    },
  ];
  const anstype = [
    {
      id: "one",
      key: "one",
      name: "one",
    },
    {
      id: "multiple",
      key: "multiple",
      name: "multiple",
    },
  ];

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(recallQuestions) });

  const [allrecalls, setAllRecall] = useState([]);
  const [options, setOptions] = useState([]);

  const getRecallData = async () =>
  {
    try
    {
      const response = await API.getAllRecalls();
      setAllRecall(response?.data?.data);
    } catch (error)
    {
      errorToast(error, "Cannot fetch recalls");
    }
  };

  const [allcategory, setAllCategory] = useState([]);

  const getCategoryRecallData = async () =>
  {
    try
    {
      const response = await API.getAllCategoriesRecall();
      setAllCategory(response?.data?.data);
    } catch (error)
    {
      errorToast(error, "Cannot fetch recalls");
    }
  };

  useEffect(() =>
  {
    getRecallData();
    getCategoryRecallData();
  }, []);

  const onSubmit = async (formdata) =>
  {
    // Format date as YYYY-MM-01
    let questionDate = null;
    if (formdata?.questionDate)
    {
      // Add "-01" to the month value to create YYYY-MM-01 format
      questionDate = `${formdata.questionDate}-01`;
    }

    const payload = {
      name: formdata?.name,
      isTrial: Boolean(formdata?.isTrial),
      isLive: Boolean(formdata?.isLive),
      difficultyMode: formdata?.difficultyMode,
      questionDate: questionDate,
      recallId: Number(formdata?.recallId),
      categoryRecallId: Number(formdata?.categoryRecallId),
      answers: {
        type: formdata?.type,
        answerDetails: formdata?.answerDetails,
        referenceDetails: " ",
        options: options,
      },
    };

    setLoading(true);
    try
    {
      const response = await API.addQuestionRecall(payload);
      successToast(response?.data?.message);
      setLoading(false);
      navigate(-1);
    } catch (error)
    {
      setLoading(false);
      errorToast(error, "Cannot add question");
    }
  };

  const handleCancle = () =>
  {
    navigate(-1);
  };

  return (
    <div className="page-area mt-10">
      <Header
        pagetitle={"Questions"}
        previous={"Dashboard"}
        currentpage={"Add Questions"}
      />
      <form className="grid grid-col-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="page-comp bg-white mt-10 rounded-xl px-8 py-8">
          <div className="grid grid-col-1 sm:grid-cols-3 gap-4  ">
            <InputField
              label="Recall Name"
              type="select"
              options={allrecalls}
              placeholder="Recall Name"
              errors={errors}
              name="recallId"
              register={register}
            />
            <InputField
              label="Category Recall Name"
              type="select"
              options={allcategory}
              placeholder="Category Recall Name"
              errors={errors}
              name="categoryRecallId"
              register={register}
            />
            <InputField
              label="Question Date"
              type="month"
              placeholder="Select Month and Year"
              errors={errors}
              name="questionDate"
              register={register}
            />
          </div>
          <div className="grid grid-col-1 sm:grid-cols-3 gap-4 mt-4">
            <InputField
              label="Difficulty Level"
              placeholder="Difficulty Level"
              type="select"
              options={difficultyMode}
              errors={errors}
              name="difficultyMode"
              register={register}
            />
            <InputField
              label="Status"
              type="select"
              options={[true, false]}
              isInvalid={isInvalid}
              placeholder="Status"
              isRequired={true}
              errors={errors}
              name="isLive"
              defaultValue={false}
              register={register}
            />
            <InputField
              label="isTrial"
              type="select"
              options={[true, false]}
              isInvalid={isInvalid}
              placeholder="isTrial"
              isRequired={false}
              errors={errors}
              name="isTrial"
              defaultValue={false}
              register={register}
            />
          </div>
          <div className="grid grid-col-1  gap-4  mt-8 mb-4">
            <Editor
              label="Statement"
              errors={errors}
              name="name"
              register={register}
              setValue={setValue}
            />
          </div>
          <div className="grid grid-col-1 sm:grid-cols-1 gap-4 mt-4">
            <InputField
              label="Answer Type:"
              placeholder="Answer Type:"
              type="select"
              options={anstype}
              errors={errors}
              name="type"
              register={register}
            />
            {watch("type") === "one" && (
              <RadioOptions
                defaultValue={"add options"}
                errors={errors}
                name="options"
                register={register}
                control={control}
                setOptions={setOptions}
                options={options}
              />
            )}
            {watch("type") === "multiple" && (
              <CheckboxOptions
                defaultValue={"add options"}
                errors={errors}
                name="options"
                register={register}
                control={control}
                setOptions={setOptions}
                options={options}
              />
            )}
          </div>
          <div className="grid grid-col-1  gap-4  mt-8 mb-4">
            <Editor
              label="Answer Details:"
              errors={errors}
              name="answerDetails"
              register={register}
              setValue={setValue}
            />
          </div>
          <div className="grid grid-col-1  gap-4  mt-8 mb-4">
            <Editor
              label="Reference Details::"
              errors={errors}
              name="referenceDetails"
              register={register}
              setValue={setValue}
            />
          </div>
          <div className="w-full md:w-1/4 mt-4">
            <div className="flex gap-3">
              <ButtonComponent
                type="submit"
                text="Save"
                loading={loading}
                isActive={true}
              />
              <ButtonComponent
                text="Cancel"
                isActive={true}
                btnclass={"bg-red-500"}
                onClick={() => handleCancle()}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddQuestions;
