import * as Yup from "yup";

const recallQuestions = Yup.object().shape({
  recallId: Yup.string().required("Exam Name is required"),
  categoryRecallId: Yup.string().required("Category Name is required"),
  difficultyMode: Yup.string().required("Difficulty Level is required"),
  questionDate: Yup.string().optional("Question Date is optional"),
  isLive: Yup.boolean().required("Status is required"),
  isTrial: Yup.boolean().required("Trial is required"),
  name: Yup.string().required("Statement is required"),
  type: Yup.string().required("Answer Type is required"),

  answerDetails: Yup.string().required("Answer Details is required"),
  // referenceDetails: Yup.string().required("Reference Details is required"),
});

export { recallQuestions };
