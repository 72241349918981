import { createBrowserRouter } from "react-router-dom";
import ForgotPassword from "../pages/signin/ForgotPassword";
import ResetPassword from "../pages/signin/ResetPassword";
import LogIn from "../pages/signin/LogIn";
import RootLayout from "../components/layout/RootLayout";
import Indexdashboard from "../pages/dashboarddefault/Indexdashboard";
import ExamComponent from "../pages/ExamsManagement/exam/ExamComponent";
import RecallComponent from "../pages/RecallsManagement/recall/RecallComponent";
import ExamCategories from "../pages/ExamsManagement/Categories/ExamCategories";
import RecallCategories from "../pages/RecallsManagement/Categories/RecallCategories";
import AddExam from "../pages/ExamsManagement/exam/AddExam";
import AddRecall from "../pages/RecallsManagement/recall/AddRecall";
import AddCategories from "../pages/ExamsManagement/Categories/AddCategories";
import AddRecallCategories from "../pages/RecallsManagement/Categories/AddCategories";
import PromoCode from "../pages/ExamsManagement/PromoCode/PromoCode";
import RecallPromoCode from "../pages/RecallsManagement/PromoCode/PromoCode";
import AddPromoCode from "../pages/ExamsManagement/PromoCode/AddPromoCode";
import AddRecallPromoCode from "../pages/RecallsManagement/PromoCode/AddPromoCode";
import EditExam from "../pages/ExamsManagement/exam/EditExam";
import EditRecall from "../pages/RecallsManagement/recall/EditRecall";
import EditCategory from "../pages/ExamsManagement/Categories/EditCategory";
import EditRecallCategory from "../pages/RecallsManagement/Categories/EditCategory";
import SubscriptionComponent from "../pages/SubscriptionManagement/SubscriptionPackages/SubscriptionComponent";
import RecallSubscriptionComponent from "../pages/RecallSubscriptionManagement/SubscriptionPackages/SubscriptionComponent";
import AddSubscription from "../pages/SubscriptionManagement/SubscriptionPackages/AddSubscription";
import EditSubscription from "../pages/SubscriptionManagement/SubscriptionPackages/EditSubscription";
import AddRecallSubscription from "../pages/RecallSubscriptionManagement/SubscriptionPackages/AddSubscription";
import EditRecallSubscription from "../pages/RecallSubscriptionManagement/SubscriptionPackages/EditSubscription";
import EditPromoCode from "../pages/ExamsManagement/PromoCode/EditPromoCode";
import EditRecallPromoCode from "../pages/RecallsManagement/PromoCode/EditPromoCode";
import TopicsComponents from "../pages/ExamsManagement/Topics/TopicsComponents";
import AddTopic from "../pages/ExamsManagement/Topics/AddTopic";
import EditTopics from "../pages/ExamsManagement/Topics/EditTopics";
import QuestionsComponent from "../pages/ExamsManagement/Questions/QuestionsComponent";
import RecallQuestionsComponent from "../pages/RecallsManagement/Questions/QuestionsComponent";
import AddQuestions from "../pages/ExamsManagement/Questions/AddQuestions";
import AddRecallQuestions from "../pages/RecallsManagement/Questions/AddQuestions";
import EditQuestions from "../pages/ExamsManagement/Questions/EditQuestions";
import EditRecallQuestions from "../pages/RecallsManagement/Questions/EditQuestions";
import AllMember from "../pages/MembersManagement/AllMember";
import AddMember from "../pages/MembersManagement/AddMember";
import EditMember from "../pages/MembersManagement/EditMember";
import SubscribedMembers from "../pages/MembersManagement/SubscribedMembers";
import ProfileDetails from "../pages/profile/ProfileDetails";
import ProfileUpdate from "../pages/profile/ProfileUpdate";
import ProfilePassword from "../pages/profile/ProfilePassword";
import ErrorPage from "../pages/ErrorPage";
import ProtectedRoutes from "../components/general/ProtectedRoutes";
import FeedBack from "../pages/SiteManagement/FeedBack";
import FeedBackRecall from "../pages/SiteManagement/FeedBack-Recall";
import Reports from "../pages/SiteManagement/Reports";
import ReportsRecall from "../pages/SiteManagement/Reports-Recall";
import Newsletter from "../pages/SiteManagement/Newsletter";
import AddSubscribedMembers from "../pages/MembersManagement/AddSubscribedMembers";
import EditSubscribedMembers from "../pages/MembersManagement/EditSubscribedMembers";
import BlogsComponent from "../pages/SiteManagement/blogs/BlogsComponent";
import AddBlogs from "../pages/SiteManagement/blogs/AddBlogs";
import EditBlogs from "../pages/SiteManagement/blogs/EditBlogs";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LogIn />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/confirm-password",
    element: <ResetPassword />,
  },

  {
    path: "/dashboard",
    element: (
      <ProtectedRoutes>
        <RootLayout />
      </ProtectedRoutes>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Indexdashboard />,
      },
      {
        path: "allexam",
        element: <ExamComponent />,
      },
      {
        path: "exam/exam-categories",
        element: <ExamCategories />,
      },
      {
        path: "allexam/add-exam",
        element: <AddExam />,
      },
      {
        path: "allexam/edit-exam",
        element: <EditExam />,
      },
      {
        path: "allrecall",
        element: <RecallComponent />,
      },
      {
        path: "recall/recall-categories",
        element: <RecallCategories />,
      },
      {
        path: "allrecall/add-recall",
        element: <AddRecall />,
      },
      {
        path: "allrecall/edit-recall",
        element: <EditRecall />,
      },
      {
        path: "recall/recall-categories/add-categories",
        element: <AddRecallCategories />,
      },
      {
        path: "recall/recall-categories/edit-category",
        element: <EditRecallCategory />,
      },
      {
        path: "recall/promo-code",
        element: <RecallPromoCode />,
      },
      {
        path: "recall/promo-code/add-promo-code",
        element: <AddRecallPromoCode />,
      },
      {
        path: "recall/promo-code/edit-promo-code",
        element: <EditRecallPromoCode />,
      },
      {
        path: "recall/questions",
        element: <RecallQuestionsComponent />,
      },
      {
        path: "recall/questions/add-question",
        element: <AddRecallQuestions />,
      },
      {
        path: "recall/questions/edit-question",
        element: <EditRecallQuestions />,
      },
      // {
      //   path: "exam/recalls",
      //   // element: <RecallsComponent />
      // },
      // {
      //   path: "exam/recalls/add-recall",
      //   // element: <AddRecall />
      // },
      // {
      //   path: "exam/recalls/edit-recall",
      //   // element: <EditRecall />
      // },
      {
        path: "exam/exam-categories/add-categories",
        element: <AddCategories />,
      },
      {
        path: "exam/exam-categories/edit-category",
        element: <EditCategory />,
      },
      {
        path: "exam/promo-code",
        element: <PromoCode />,
      },
      {
        path: "exam/promo-code/add-promo-code",
        element: <AddPromoCode />,
      },
      {
        path: "exam/promo-code/edit-promo-code",
        element: <EditPromoCode />,
      },
      {
        path: "exam/topics",
        element: <TopicsComponents />,
      },
      {
        path: "exam/topics/add-topics",
        element: <AddTopic />,
      },
      {
        path: "exam/topics/edit-topic",
        element: <EditTopics />,
      },
      {
        path: "exam/questions",
        element: <QuestionsComponent />,
      },
      {
        path: "exam/questions/add-question",
        element: <AddQuestions />,
      },
      {
        path: "exam/questions/edit-question",
        element: <EditQuestions />,
      },
      {
        path: "all-subscription-packages",
        element: <SubscriptionComponent />,
      },
      {
        path: "subscription-packages/add-subscription",
        element: <AddSubscription />,
      },
      {
        path: "all-subscription-packages/edit-subscription",
        element: <EditSubscription />,
      },
      {
        path: "all-subscription-recall-packages",
        element: <RecallSubscriptionComponent />,
      },
      {
        path: "subscription-recall-packages/add-subscription",
        element: <AddRecallSubscription />,
      },
      {
        path: "all-subscription-recall-packages/edit-subscription",
        element: <EditRecallSubscription />,
      },

      {
        path: "members-management",
        children: [
          {
            path: "all-members",
            element: <AllMember />,
          },
          {
            path: "add-members",
            element: <AddMember />,
          },
          {
            path: "all-members/edit-members",
            element: <EditMember />,
          },
          {
            path: "subscribed-members",
            element: <SubscribedMembers />,
          },
          {
            path: "subscribed-members/add-subscribed-members",
            element: <AddSubscribedMembers />,
          },
          {
            path: "subscribed-members/edit-subscribed-members",
            element: <EditSubscribedMembers />,
          },
        ],
      },
      {
        path: "profile",
        children: [
          {
            path: "details",
            element: <ProfileDetails />,
          },
          {
            path: "update",
            element: <ProfileUpdate />,
          },
          {
            path: "password",
            element: <ProfilePassword />,
          },
        ],
      },
      {
        path: "blogs",
        children: [
          {
            path: "",
            element: <BlogsComponent />,
          },
          {
            path: "add",
            element: <AddBlogs />,
          },
          {
            path: "edit",
            element: <EditBlogs />,
          },
        ],
      },
      {
        path: "feeback",
        element: <FeedBack />,
      },
      {
        path: "feedback-recall",
        element: <FeedBackRecall />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "report-recall",
        element: <ReportsRecall />,
      },
      {
        path: "newsletter",
        element: <Newsletter />,
      },
    ],
  },
]);

export { router };
